<template>
  <div class="apply-store d-flex flex-column align-center mx-4">
    <div class="text-h4 mt-16 mb-8">{{ $t("__apply_store_title") }}</div>
    <v-img max-width="600px" :src="require('@/assets/chia-promotion.jpg')" />
    <v-stepper class="no-shadow">
      <v-stepper-header>
        <v-stepper-step step="1"> {{ $t("__fill_info_form") }} </v-stepper-step>
        <v-divider class="progress-divider"></v-divider>
        <v-stepper-step step="2"> {{ $t("__upload_photo") }} </v-stepper-step>
        <v-divider class="progress-divider"></v-divider>
        <v-stepper-step step="3"> {{ $t("__completed") }} </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-card flat max-width="600" width="100%" class="pb-0">
      <v-card-title class="justify-center">{{
        $t("__provide_information")
      }}</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col :cols="isMobile ? 12 : 6">
            <v-card outlined height="100%">
              <v-card-title class="justify-center bg-primary">{{
                $t("__personal_member")
              }}</v-card-title>
              <v-card-text class="pa-0">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__store_website")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__owner_id_card_photo")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__store_gate_photo")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__store_location_photo")
                    }}</v-list-item-title>
                    <v-list-item-content class="grey--text pt-0">{{
                      $t("__store_location_photo_description")
                    }}</v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__storehouse_photo")
                    }}</v-list-item-title>
                    <v-list-item-content class="grey--text pt-0">{{
                      $t("__storehouse_photo_description")
                    }}</v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :cols="isMobile ? 12 : 6">
            <v-card outlined height="100%">
              <v-card-title class="justify-center bg-primary">{{
                $t("__business_member")
              }}</v-card-title>
              <v-card-text class="pa-0">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__store_website")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__owner_id_card_photo")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__store_gate_photo")
                    }}</v-list-item-title>
                    <v-list-item-content class="grey--text pt-0">
                      {{ $t("__store_gate_photo_description") }}
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__store_location_photo")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__storehouse_photo")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("__signboard_and_doorplate_photo")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="ma-2" elevation="0" max-width="600" width="100%">
      <v-card-text class="pa-6">
        <CreateStoreApplicationForm
          ref="form"
          :form="form"
          @update:form="form = $event"
          :valid="valid"
          @update:valid="valid = $event"
        />
        <div class="caption mb-2 error--text">{{ error }}</div>
        <div class="d-flex">
          <v-btn class="mr-3" outlined color="grey" :to="{ name: 'Home' }">
            {{ $t("__cancel") }}
          </v-btn>
          <v-btn
            outlined
            color="light-blue"
            @click="saveForm"
            :disabled="!form.store_name"
          >
            {{ $t("__save") }}
          </v-btn>
          <v-btn
            :disabled="!valid"
            class="ml-auto"
            color="success"
            @click="applyStore"
          >
            {{ $t("__next_step") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateStoreApplicationForm from "@/components/CreateStoreApplicationForm";

export default {
  name: "ApplyStore",
  components: {
    CreateStoreApplicationForm
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      createdForm: "createStoreApplicationForm/createStoreApplicationForm"
    })
  },
  data() {
    return {
      form: {
        store_name: "",
        store_industry_type: "retail",
        owner_name: "",
        owner_phone_number: "",
        owner_email: "",
        store_owner_type: "company",
        tax_id_number: "",
        store_phone_number: "",
        store_address_detail: {
          postal_code: "000",
          city: "",
          area: "",
          road: "",
          lane: "",
          alley: "",
          number: "",
          sub_number: "",
          floor: "",
          room: ""
        },
        store_bank_code: "",
        store_bank_account: "",
        store_website: "",
        telegram_bot_token: ""
      },
      steps: [
        {
          step: "STEP 1.",
          icon: "mdi-clipboard-list-outline",
          text: this.$t("__fill_info_form")
        },
        {
          step: "STEP 2.",
          icon: "mdi-cloud-upload-outline",
          text: this.$t("__upload_photo")
        },
        {
          step: "STEP 3.",
          icon: "mdi-check-bold",
          text: this.$t("__completed")
        }
      ],
      valid: false,
      error: "",
      completed: false
    };
  },
  methods: {
    saveForm() {
      this.completed = false;
      this.$store.dispatch("setIsLoading", true);
      this.addCreateForm();
    },
    addCreateForm() {
      if (!this.form["store_address_detail"]["sub_number"]) {
        this.form["store_address_detail"]["sub_number"] = 0;
      }
      this.$store
        .dispatch("createStoreApplicationForm/addCreateStoreApplicationForm", {
          formData: this.form
        })
        .then(createStoreApplicationFormID => {
          this.$store.dispatch("setIsLoading", false);
          if (this.completed) {
            this.$router.replace({
              name: "UploadApplyStorePhoto",
              params: { id: createStoreApplicationFormID }
            });
          } else {
            this.$router.push({
              name: "CreateStoreApplicationForms"
            });
          }
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          this.error = err.message;
        });
    },
    applyStore() {
      this.$refs.form.validate();
      this.completed = true;
      if (this.valid) {
        this.addCreateForm();
      }
    }
  }
};
</script>
<style lang="scss">
.progress-divider {
  border-width: thin 130px 0 0;
}
.v-stepper.no-shadow {
  box-shadow: none;
}
</style>
